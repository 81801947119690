<template>
  <div class="w-100 d-flex justify-content-between location">
    <div class="d-flex">
      <div class="mr-1">{{ number }}.</div>
      <div class="d-inline-block">
        <b
            :class="{underline_hover: isAddressClickable}"
            @click="addressClicked"
        >
          {{ generatedAddress }}{{ state ? `, ${$t(state)}` : '' }}
        </b>
        <div v-if="item.note">
          <span class="note">{{ item.note }}</span>
        </div>
      </div>
    </div>
    <div>
      <font-awesome-icon
          class="ml-2 cursor-default"
          icon="building"
          style="color: #818181"
          v-if="item.customerLocalizationId || item.address_id"
      />
      <font-awesome-icon
          @click.stop="removeItem"
          class="text-danger ml-3"
          icon="times"
          slot="reference"
          v-if="showDeleteButton"
      />
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';

export default {
  name: 'LocalizationListItem',
  props: {
    number: {
      type: [String, Number],
    },
    item: {
      type: Object,
      required: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    isAddressClickable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    state() {
      if (this.item.state && this.item.state.name) {
        return `system.${this.item.state.name.split(' ').join('_').toLowerCase()}`;
      }
      return null;
    },
    generatedAddress() {
      const addressFields = [
        this.item.address,
        this.item.city,
      ];
      return addressFields.filter(item => !!item).join(', ');
    },
  },
  methods: {
    async removeItem() {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        this.$emit('remove');
      }
    },
    addressClicked() {
      this.$emit('addressClicked');
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.location {
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  margin-bottom: 5px;
  border-bottom: 1px solid rgb(218, 222, 229);
  padding: 0 8px 5px;

  .note {
    font-size: .9em;
    line-height: 1em;
    color: #7e7e7e;
  }
}
</style>
