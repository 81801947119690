var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentWrapper',[_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.ifHasPermission(['wo-order-create']))?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.$router.push({name: 'WorkOrderCreate'})}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('system.work_order'))+" ")],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('el-select',{staticStyle:{"width":"230px"},attrs:{"placeholder":_vm.$t('system.select_status'),"multiple":"","collapse-tags":"","size":"small","loading":_vm.statusesLoading},on:{"change":function($event){return _vm.fetchData(true)}},model:{value:(_vm.params.status_id),callback:function ($$v) {_vm.$set(_vm.params, "status_id", $$v)},expression:"params.status_id"}},_vm._l((_vm.statuses),function(status){return _c('el-option',{key:status.ids,attrs:{"label":_vm.$t(("system." + (status.name))),"value":status.id}})}),1),_c('DebounceInput',{staticClass:"ml-2",staticStyle:{"max-width":"220px"},attrs:{"placeholder":_vm.$t('system.search'),"clearable":"","size":"small"},on:{"change":function($event){return _vm.fetchData(true)}},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1)],1),_c('el-divider'),_c('DataTable',{attrs:{"headers":_vm.headers,"loading":_vm.tableLoading && !_vm.data.length,"model":_vm.data,"params":_vm.params,"idPath":"id"},on:{"update:params":function($event){_vm.params=$event},"clickRow":_vm.onClickRow,"updateData":_vm.fetchData},scopedSlots:_vm._u([{key:"item.account_name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"m-2",staticStyle:{"white-space":"normal"}},[(row.account_name)?_c('b',[_vm._v(_vm._s(row.account_name))]):_c('span',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('system.no_data')))])])]}},{key:"item.localizations",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"m-1",staticStyle:{"white-space":"normal"}},[(row.locations.length)?_c('div',_vm._l((row.locations),function(item,idx){return _c('LocalizationListItem',{key:item.id,attrs:{"isAddressClickable":false,"item":item,"number":idx + 1,"showDeleteButton":false}})}),1):_c('span',{staticClass:"no-data"},[_vm._v(_vm._s(_vm.$t('system.no_data')))])])]}},{key:"item.managers",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"m-2",staticStyle:{"white-space":"normal"}},_vm._l((row.managers),function(manager){return _c('div',{key:manager.id,staticClass:"w-100"},[_c('font-awesome-icon',{attrs:{"icon":"user-tie"}}),_vm._v(" "+_vm._s(manager.name)+" ")],1)}),0)]}},{key:"item.technicians",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"m-2",staticStyle:{"white-space":"normal"}},_vm._l((row.technicians),function(tech){return _c('div',{key:tech.id,staticClass:"w-100"},[_c('font-awesome-icon',{attrs:{"icon":"user-cog"}}),_vm._v(" "+_vm._s(tech.name)+" ")],1)}),0)]}},{key:"item.status_name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"m-2",staticStyle:{"white-space":"normal"}},[_c('el-tag',{staticClass:"tag-rounded",attrs:{"effect":"dark","size":"small","type":"success"}},[_vm._v(" "+_vm._s(_vm.$t(("system." + (row.status_name))))+" ")])],1)]}},{key:"item.options",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"m-2",staticStyle:{"white-space":"normal"},on:{"click":function($event){$event.stopPropagation();}}},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleOptionCommand.apply(void 0, argsArray.concat( [row.id] ))}}},[_c('el-button',{attrs:{"size":"mini","type":"info"}},[_vm._v(" "+_vm._s(_vm.$t('system.options'))),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.ifHasPermission(['wo-order-edit']))?_c('el-dropdown-item',{attrs:{"command":'goToEdit'}},[_vm._v(" "+_vm._s(_vm.$t("system.edit"))+" ")]):_vm._e(),_c('el-dropdown-item',{attrs:{"command":'remove'}},[_vm._v(" "+_vm._s(_vm.$t("system.delete"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }