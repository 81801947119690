<template>
  <ContentWrapper>
    <div class="d-flex justify-content-between">
      <el-button
          @click="$router.push({name: 'WorkOrderCreate'})"
          size="small"
          type="primary"
          v-if="ifHasPermission(['wo-order-create'])"
      >
        <font-awesome-icon
            class="mr-1"
            icon="plus"
        />
        {{ $t('system.work_order') }}
      </el-button>
      <div class="d-flex">
        <el-select
            v-model="params.status_id"
            :placeholder="$t('system.select_status')"
            multiple
            collapse-tags
            size="small"
            style="width: 230px"
            :loading="statusesLoading"
            @change="fetchData(true)"
        >
          <el-option
              :key="status.ids"
              :label="$t(`system.${status.name}`)"
              :value="status.id"
              v-for="status in statuses"
          />
        </el-select>
        <DebounceInput
            :placeholder="$t('system.search')"
            @change="fetchData(true)"
            clearable
            size="small"
            class="ml-2"
            style="max-width: 220px"
            v-model="params.search"
        />
      </div>
    </div>
    <el-divider />
    <DataTable
        :headers="headers"
        :loading="tableLoading && !data.length"
        :model="data"
        :params.sync="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
        idPath="id"
    >
      <template v-slot:item.account_name="{row}">
        <div
            class="m-2"
            style="white-space: normal"
        >
          <b v-if="row.account_name">{{ row.account_name }}</b>
          <span
              class="no-data"
              v-else
          >{{ $t('system.no_data') }}</span>
        </div>
      </template>
      <template v-slot:item.localizations="{row}">
        <div
            class="m-1"
            style="white-space: normal"
        >
          <div v-if="row.locations.length">
            <LocalizationListItem
                :isAddressClickable="false"
                :item="item"
                :key="item.id"
                :number="idx + 1"
                :showDeleteButton="false"
                v-for="(item, idx) in row.locations"
            />
          </div>
          <span
              class="no-data"
              v-else
          >{{ $t('system.no_data') }}</span>
        </div>
      </template>
      <template v-slot:item.managers="{row}">

        <div
            class="m-2"
            style="white-space: normal"
        >
          <div
              :key="manager.id"
              class="w-100"
              v-for="manager in row.managers"
          >
            <font-awesome-icon icon="user-tie" />
            {{ manager.name }}
          </div>
        </div>
      </template>
      <template v-slot:item.technicians="{row}">
        <div
            class="m-2"
            style="white-space: normal"
        >
          <div
              :key="tech.id"
              class="w-100"
              v-for="tech in row.technicians"
          >
            <font-awesome-icon icon="user-cog" />
            {{ tech.name }}
          </div>
        </div>
      </template>
      <template v-slot:item.status_name="{row}">
        <div
            class="m-2"
            style="white-space: normal"
        >
          <el-tag
              class="tag-rounded"
              effect="dark"
              size="small"
              type="success"
          >
            {{ $t(`system.${row.status_name}`) }}
          </el-tag>
        </div>
      </template>
      <template v-slot:item.options="{row}">
        <div
            @click.stop
            class="m-2"
            style="white-space: normal"
        >
          <el-dropdown
              @command="handleOptionCommand(...arguments, row.id)"
              trigger="click"
          >
            <el-button
                size="mini"
                type="info"
            >
              {{ $t('system.options') }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                  :command="'goToEdit'"
                  v-if="ifHasPermission(['wo-order-edit'])"
              >
                {{ $t(`system.edit`) }}
              </el-dropdown-item>
              <el-dropdown-item
                  :command="'remove'"
              >
                {{ $t(`system.delete`) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </DataTable>
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
import DataTable from '../../components/Table/DataTable.vue';
import ContentWrapper from '../../components/HOCs/ContentWrapper';
import DebounceInput from '../../components/DebounceInput.vue';
import LocalizationListItem from '@/components/WorkOrderComponents/Localizations/LocalizationListItem';

export default {
  name: 'WorkOrdersList',
  components: {
    LocalizationListItem,
    DebounceInput,
    ContentWrapper,
    DataTable,
  },
  data() {
    return {
      headers: [
        {
          key: 'id',
          label: 'system.id',
        },
        {
          key: 'account_name',
          label: 'system.client',
        },
        {
          key: 'localizations',
          label: 'system.localizations',
          sort: false,
        },
        {
          key: 'managers',
          label: 'system.managers',
          sort: false,
        },
        {
          key: 'technicians',
          label: 'system.technicians',
          sort: false,
        },
        {
          key: 'created_at',
          label: 'system.created_at',
        },
        {
          key: 'start_at',
          label: 'system.start_at',
        },
        {
          key: 'done_at',
          label: 'system.done_at',
        },
        {
          key: 'priority_name',
          label: 'system.priority',
        },
        {
          key: 'status_name',
          label: 'system.status',
        },
        {
          key: 'options',
          label: 'system.options',
          sort: false,
        },
      ],
      data: [],
      tableLoading: false,
      params: {
        status_id: [],
        search: '',
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
        total: 0,
        per_page_sizes: [10, 30, 50, 100],
      },
      statuses: [],
      statusesLoading: false,
    };
  },
  activated() {
    this.fetchData();
  },
  beforeMount() {
    this.fetchStatuses();
  },
  methods: {
    async fetchStatuses() {
      this.statusesLoading = true;
      try {
        const {data} = await axios.get('/workorders/statuses');
        this.statuses = data.map(item => ({
          ...item,
          name: item.name.split(' ').join('_').toLowerCase(),
        }));
      } catch (e) {
        console.log('fetchStatuses error', e);
      } finally {
        this.statusesLoading = false;
      }
    },
    async fetchData(foToFirstPage = false) {
      this.tableLoading = true;
      try {
        if (foToFirstPage) {
          this.params.page = 1;
        }
        const {data} = await axios.get('/workorders/order', {
          params: this.params,
        });
        this.data = data.data;
        this.params.total = data.total;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.tableLoading = false;
      }
    },
    onClickRow(id) {
      this.$router.push({
        name: 'WorkOrder',
        params: {id},
      });
    },
    async goToEdit(id) {
      await this.$router.push({
        name: 'WorkOrderEdit',
        params: {id},
      });
    },
    async remove(id) {
      const result = await swal.fire({
        target: this.$refs.root,
        title: this.$t('system.remove'),
        text: this.$t('system.if_you_sure'),
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('system.yes'),
        cancelButtonText: this.$t('system.no'),
      });
      if (result.value) {
        try {
          await axios.delete(`workorders/order/${id}`);
        } catch (e) {
          //
        } finally {
          await this.fetchData();
        }
      }
    },
    handleOptionCommand(command, component, rowId) {
      this[command](rowId);
    },
  },
};
</script>

<style scoped>

</style>
